import React from 'react';
import { isBrowser } from 'react-device-detect';
import { connect } from 'react-redux';
import './SlotItem.css';

const SlotItem = ({
  items = [],
  isDateSelected,
  isInitalDateSelected,
  onSlotClick = () => {},
}) => {
  if (isInitalDateSelected && !isDateSelected) {
    return (
      <div className="slot-item-parent-container">
        <p className="no-slot-date-text">
          Please select a date to see available slots.
        </p>
      </div>
    );
  }
  if (isDateSelected && items.length < 1) {
    return (
      <div className="slot-item-parent-container">
        <p className="no-slot-found-text">
          No available slot found for selected date. Please select different
          date or contact provider.
        </p>
      </div>
    );
  }
  return (
    <div
      className="slot-item-parent-container"
      style={{ maxHeight: isBrowser ? '400px' : '100%' }}
    >
      <div>
        {items.map((el, ind) => (
          <div
            key={ind}
            onClick={() => onSlotClick(el)}
            className="slot-item-container"
            style={{ width: isBrowser ? '180px' : '320px' }}
          >
            <p className="slot-item-text">{el['start']}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SlotItem);
