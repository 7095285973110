import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Button,
  Grid,
  Box,
  Radio,
  FormGroup,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { AccessTime, AttachMoney } from '@mui/icons-material';
import {
  Loader,
  Error,
  IconLabel,
  ArrowBackButton,
  VisibleContainer,
  PrimaryTextInput,
  SingleSelectDropdown,
} from '../../../components';
import { isBrowser } from 'react-device-detect';
import { Colors } from '../../../config';
import PackageDropdown from './PackageDropdown';

const PreFormUI = ({
  type,
  team,
  amount,
  image,
  name,
  overview,
  packageDesc,
  duration,
  aptType,
  aptChoice,
  isCashPayment,
  providerDetails,
  onProviderChange,
  onCashPaymentChange,
  selectedPsychDetails,
  onAptChoiceChange,
  onAptTypeChange,
}) => {
  // console.log('selectedPsychDetails: ', selectedPsychDetails);
  // console.log('providerDetails: ', providerDetails);

  const [isReadMore, setIsReadMore] = useState(false);

  const getProviderDropdown = () => {
    let tempArray = [];
    providerDetails.forEach((item, index) => {
      let tempIsActive = false;
      item['psych_charges'].forEach((it, ind) => {
        if (it['active']) {
          tempIsActive = true;
        }
      });
      if (tempIsActive) {
        tempArray.push({
          label: item.psych_details['name'],
          value: item.psych_details['id'].toString(),
        });
      }
      tempIsActive = false;
    });
    return tempArray;
  };
  const getTypeOfApt = (psych_charges_with_packages) => {
    let finalArray = [];
    if (!!psych_charges_with_packages) {
      const entryKeys = Object.keys(psych_charges_with_packages);
      entryKeys.forEach((item) =>
        finalArray.push(psych_charges_with_packages[item])
      );
    }
    return finalArray;
  };
  if (!selectedPsychDetails) return;
  if (
    !selectedPsychDetails.psych_settings['allow_public_appointment'][
      'display'
    ] ||
    selectedPsychDetails['psych_charges_with_packages'].length == 0
  ) {
    return (
      <div
        style={{
          marginLeft: isBrowser ? '' : '8px',
          marginRight: isBrowser ? '' : '8px',
        }}
      >
        <img
          style={{ width: 100, height: 100, borderRadius: 400 / 2 }}
          src={
            selectedPsychDetails.psych_details['psychiatrist_details'][
              'profile_image_url'
            ]
              ? selectedPsychDetails.psych_details['psychiatrist_details'][
                  'profile_image_url'
                ]
              : 'https://s3.us-west-2.amazonaws.com/psychonline.upload/defaultpic.png'
          }
        />
        <p style={{ color: Colors.black, textAlign: 'left' }}>
          <b>Provider Name:</b> {selectedPsychDetails.psych_details['name']}
        </p>
        <p style={{ color: Colors.black, textAlign: 'left' }}>
          <b>About:</b>{' '}
          {
            selectedPsychDetails.psych_details['psychiatrist_details'][
              'overview'
            ]
          }
        </p>
        <p style={{ color: Colors.danger, textAlign: 'center' }}>
          {selectedPsychDetails['psych_charges_with_packages'].length == 0
            ? 'The provider has not enabled the fees setting, please contact provider for more details.'
            : selectedPsychDetails.psych_settings['allow_public_appointment'][
                'message'
              ]}
        </p>
        <FormControl fullWidth>
          {type == 'clinic' && (
            <SingleSelectDropdown
              marginTop={8}
              marginBottom={8}
              label="Select provider*"
              items={getProviderDropdown()}
              value={selectedPsychDetails.psych_details['id']}
              onChange={(value) => onProviderChange(value)}
            />
          )}
        </FormControl>
      </div>
    );
  }
  return (
    <div
      style={{
        marginLeft: isBrowser ? '' : '8px',
        marginRight: isBrowser ? '' : '8px',
      }}
    >
      <img
        style={{ width: 100, height: 100, borderRadius: 400 / 2 }}
        src={
          image
            ? image
            : 'https://s3.us-west-2.amazonaws.com/psychonline.upload/defaultpic.png'
        }
      />
      <p style={{ color: Colors.black, textAlign: 'justify' }}>
        <b>Provider Name:</b> {`${name} `}
      </p>
      {overview ? (
        <p style={{ color: Colors.black, textAlign: 'justify' }}>
          <b>About:</b> {`${overview} `}
        </p>
      ) : (
        ''
      )}
      <IconLabel
        label={`${duration} min`}
        Icon={<AccessTime />}
        marginBottom="8px"
      />
      <IconLabel
        label={`${amount} USD`}
        Icon={<AttachMoney />}
        marginBottom="8px"
      />
      <FormControl fullWidth>
        {type == 'clinic' && (
          <SingleSelectDropdown
            marginTop={8}
            marginBottom={8}
            label="Select provider*"
            items={getProviderDropdown()}
            value={selectedPsychDetails.psych_details['id']}
            onChange={(value) => onProviderChange(value)}
          />
        )}
        <FormLabel id="demo-radio-buttons-group-label">
          Appointment Choice*
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={aptChoice}
        >
          {selectedPsychDetails['choice_of_appointment'].map((item, index) => {
            if (item['checked']) {
              return (
                <FormControlLabel
                  key={index}
                  onChange={(event) => onAptChoiceChange(event.target.value)}
                  value={item.value.toString()}
                  control={<Radio />}
                  label={item['label']}
                />
              );
            }
          })}
        </RadioGroup>
      </FormControl>
      <PackageDropdown
        value={aptType}
        marginTop={10}
        onChange={(value) => onAptTypeChange(value)}
        items={getTypeOfApt(
          selectedPsychDetails['psych_charges_with_packages']
        )}
        label="Appointment Type*"
      />
      {!!packageDesc && (
        <>
          <span style={{ marginTop: 3, color: 'GrayText', fontSize: 14 }}>
            <span style={{ color: 'black' }}>Description: </span>
            {isReadMore ? packageDesc : packageDesc.substring(0, 140)}
            <span
              onClick={() => setIsReadMore(!isReadMore)}
              className="read-more-btn"
            >
              {isReadMore ? 'Read less' : 'Read more...'}
            </span>
          </span>
        </>
      )}
      <VisibleContainer
        visible={
          selectedPsychDetails.psych_settings['cash_payment'] &&
          amount !== '0.00'
        }
      >
        <FormGroup style={{ marginTop: '5px' }}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={aptChoice == '1'}
                checked={isCashPayment}
                onChange={() => onCashPaymentChange(!isCashPayment)}
              />
            }
            label="Pay Offline"
          />
        </FormGroup>
      </VisibleContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  providerDetails: state.User.providerDetails,
});

const mapDispatchToProps = (dispatch) => ({
  showSpinner: dispatch.Component.showSpinner,
  hideSpinner: dispatch.Component.hideSpinner,
});

export default connect(mapStateToProps, mapDispatchToProps)(PreFormUI);
