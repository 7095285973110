import axios from 'axios';
import moment from 'moment';
import { Envs } from '../config';

export default class ApiCall {
  constructor() {
    this.client = null;
    this.api_url = process.env.REACT_APP_API_ENDPOINT;
  }
  init = () => {
    const now = moment();
    const start_of_day = now.startOf('day').format('YYYY-MM-DD HH:mm:ss');

    let Headers = {
      'App-Region': Envs['app_region'],
      Accept: 'application/json',
      'App-Start-Date-Time': moment
        .utc(moment(start_of_day))
        .format('YYYY-MM-DD HH:mm:ss'),
      'App-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    this.client = axios.create({
      headers: Headers,
    });
    return this.client;
  };

  getProviderAvailSlots = (params) => {
    return this.init().get(
      `${Envs.psychOnlineBaseUrl}/psychiatrist/slot/get_v2`,
      { params }
    );
  };

  getProviderDetails = (params) => {
    return this.init().get(`${Envs.psychOnlineBaseUrl}/provider/calendarView`, {
      params,
    });
  };

  createNonLoggedInPatientAppointment = (payload) => {
    return this.init().post(
      `${Envs.psychOnlineBaseUrl}/book-appointment`,
      payload
    );
  };
  submitPatientDetails = (payload) => {
    return this.init().post(
      `${Envs.psychOnlineBaseUrl}/provider/no-slot-email`,
      payload
    );
  };
}

export function setApiAuthToken(authToken) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
}

export function setApiTeamId(teamID) {
  axios.defaults.headers.common['App-Origin'] = teamID;
}
