import React from 'react';
import { FormLabel } from '@mui/material';

import './PrimaryTextInput.css';

const PrimaryTextInput = ({
  type,
  label,
  name,
  value,
  isError,
  disabled,
  marginTop,
  marginBottom,
  errorMessage,
  onChange = () => {},
}) => {
  if (isError) {
    return (
      <div style={{ marginTop, marginBottom }}>
        <FormLabel id="demo-radio-buttons-group-label">
          {label && label}
        </FormLabel>
        <div style={{ marginTop: '3px' }}>
          <input
            className="input-error"
            disabled={disabled}
            type={type}
            name={name}
            value={value}
            onChange={(event) => onChange(event)}
          />
          <div style={{ color: '#dc3545', marginTop: '5px' }}>
            {errorMessage && errorMessage}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div style={{ marginTop, marginBottom }}>
      <FormLabel id="demo-radio-buttons-group-label">
        {label && label}
      </FormLabel>
      <div style={{ marginTop: '3px' }}>
        <input
          className="input-success"
          disabled={disabled}
          type={type}
          name={name}
          value={value}
          onChange={(event) => onChange(event)}
        />
      </div>
    </div>
  );
};

export default PrimaryTextInput;
