import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { PrimaryTextInput, PrimaryButton } from "../../../components";
import { isValidEmail } from "../../../services";
import { Colors } from "../../../config";

const PostFormUI = ({
  type,
  onBookApt,
  showSpinner,
  hideSpinner,
  showSnackbar,
  onParamsError,
}) => {
  // console.log("razorPayPopupData: ", razorPayPopupData);
  // console.log('type: ', type);
  const location = useLocation();
  useEffect(() => {
    if (type == "redirect") {
      const queryParams = new URLSearchParams(location.search);
      const name = queryParams.get("name");
      const email = queryParams.get("email");
      const phone = queryParams.get("phone");
      const redirecturl = queryParams.get("redirecturl");
      // console.log(name, email, phone);
      if (!name || !email || !phone) {
        onParamsError();
      } else {
        setState({
          name,
          email,
          phone,
          redirecturl,
        });
      }
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [inputErr, setInputErr] = useState({
    name: false,
    email: false,
    phone: false,
    redirecturl: null,
  });
  const [state, setState] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleForm = (event) => {
    event.preventDefault();
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const onBookAptPressed = () => {
    if (!state["name"] || !state["email"] || !state["phone"]) {
      setInputErr({
        ...inputErr,
        name: state["name"] ? false : true,
        email: state["email"] ? false : true,
        phone: state["phone"] ? false : true,
      });
      return;
    }

    if (!state["phone"].length == 10) {
      showSnackbar({ label: "Enter a valid phone number!", type: "warning" });
      return;
    }
    if (!isValidEmail(state["email"])) {
      showSnackbar({ label: "Enter a valid email!", type: "warning" });
      return;
    }
    setLoading(true);
    const success = () => setLoading(false);
    const failure = () => setLoading(false);
    onBookApt(state, success, failure);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PrimaryTextInput
            label="Your Name*"
            name="name"
            value={state["name"]}
            disabled={type == "redirect" || loading}
            isError={inputErr["name"]}
            errorMessage="This field is required"
            onChange={(event) => handleForm(event)}
          />
        </Grid>
        <Grid item xs={12}>
          <PrimaryTextInput
            label="Email Address*"
            name="email"
            value={state["email"]}
            disabled={type == "redirect" || loading}
            isError={inputErr["email"]}
            errorMessage="This field is required"
            onChange={(event) => handleForm(event)}
          />
        </Grid>
        <Grid item xs={12}>
          <PrimaryTextInput
            label="Phone Number*"
            name="phone"
            value={state["phone"]}
            disabled={type == "redirect" || loading}
            isError={inputErr["phone"]}
            errorMessage="This field is required"
            onChange={(event) => handleForm(event)}
          />
        </Grid>
      </Grid>
      <p style={{ color: Colors.info }}>
        Note: Appointment details will be sent to details above, so please
        verify before you proceed.
      </p>
      <PrimaryButton
        fullWidth
        loading={loading}
        marginTop="15px"
        label="Book Appointment"
        onClick={() => onBookAptPressed()}
      />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  showSnackbar: dispatch.Component.showSnackbar,
  showSpinner: dispatch.Component.showSpinner,
  hideSpinner: dispatch.Component.hideSpinner,
});

export default connect(mapStateToProps, mapDispatchToProps)(PostFormUI);
