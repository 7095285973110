import React from "react";
import { FormControl, Select, MenuItem, FormLabel } from "@mui/material";
import "./SingleSelectDropdown.css";

const SingleSelectDropdown = ({
  items,
  label,
  value,
  disabled,
  marginTop,
  marginBottom,
  onChange = () => {},
}) => {
  // console.log("items: ", items);
  return (
    <FormControl fullWidth style={{ marginTop, marginBottom, width: '320px' }}>
      <FormLabel className="single-select-label-text">
        {label && label}
      </FormLabel>
      <Select
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value ? value : ""}
        placeholder="please select"
      >
        {items.map((item, index) => {
          return (
            <MenuItem
              key={index}
              value={item.value}
              onClick={() => onChange(item.value)}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SingleSelectDropdown;
