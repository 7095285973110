import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Home } from '@mui/icons-material';
import { connect } from 'react-redux';
import { Card } from '@mui/material';
import moment from 'moment';
import { convertSlotsToLocalArray, setApiTeamId } from '../../services';
import { Loader, Error, ArrowBackButton, Success } from '../../components';
import { PrimaryButton } from '../../components';
import MonthCalendar from './components/MonthCalendar';
import SlotItem from './components/SlotItem';
import PreFormUI from './components/PreFormUI';
import PostFormUI from './components/PostFormUI';
import DetailForm from './components/DetailForm';
import './HomePage.css';
import { useParams } from 'react-router-dom';
import { Colors } from '../../config';

const HomePage = ({
  type,
  loading,
  showSpinner,
  hideSpinner,
  showSnackbar,
  providerDetails,
  getProviderDetails,
  getProviderAvailSlots,
  createNonLoggedInPatientAppointment,
  submitPatientDetails = () => {},
}) => {
  const { team_slug, provider_slug } = useParams();
  useEffect(() => {
    if (!providerDetails) {
      initCallProviderDetailsAPI();
    }
  }, []);
  const [image, setImage] = useState(null);
  const [count, setCount] = useState(false);
  const [name, setName] = useState(null);
  const [overview, setOverview] = useState(null);
  const [headerText, setHeaderText] = useState('Loading...');
  const [amount, setAmount] = useState(null);
  const [isError, setIsError] = useState(false);
  const [aptType, setAptType] = useState(null);
  const [utcSlots, setUtcSlots] = useState({});
  const [duration, setDuration] = useState(null);
  const [aptChoice, setAptChoice] = useState(null);
  const [localSlots, setLocalSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [isCashPayment, setIsCashPayment] = useState(false);
  const [activeMonthYearData, setActiveMonthData] = useState(null);
  const [isAptBookSuccess, setIsAptBookSuccess] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isInitalDateSelected, setIsInitalDateSelected] = useState(false);
  const [selectedPsychDetails, setSelectedPsychDetails] = useState(null);
  const [packageDesc, setPackageDesc] = useState('');
  const [team, setTeam] = useState(null);
  const [selectedDate, setSelectedDate] = useState({
    isSelected: false,
    formatted: null,
    unFormatted: null,
  });

  const initCallProviderDetailsAPI = () => {
    const payload = {
      team_slug: type == 'clinic' ? team_slug : '',
      provider_slug:
        type == 'provider' || type == 'redirect' ? provider_slug : '',
    };
    getProviderDetails({
      payload,
      callback: (response, error) => {
        // console.log('getProviderDetails: ', response);
        if (response && !error) {
          setHeaderText(response['team']['name']);
          onSelectedProviderChange(response['data'][0]);
        }
      },
    });
  };

  const callProviderSlotAPI = (monthYearData, aptType, team_id, psych_id) => {
    // console.log('callProviderSlotAPI monthYearData: ', monthYearData);
    // console.log('callProviderSlotAPI aptType: ', aptType);
    if (monthYearData && team_id && psych_id) {
      showSpinner();
      setFilteredEvents([]);
      setSelectedDate({
        isSelected: false,
        formatted: null,
        unFormatted: null,
      });
      setIsInitalDateSelected(false);
      setApiTeamId(team_id);
      setTeam(team_id);
      getProviderAvailSlots({
        payload: {
          type: aptType,
          psych_id: psych_id,
          start_date: monthYearData['isCurrentMonth']
            ? moment().utc().format('YYYY-MM-DD') + 'T00:00:00Z'
            : moment(monthYearData['monthYear'], 'MMMM-YYYY')
                .startOf('month')
                .utc()
                .format('YYYY-MM-DD') + 'T00:00:00Z',
          end_date:
            moment(monthYearData['monthYear'], 'MMMM-YYYY')
              .endOf('month')
              .utc()
              .format('YYYY-MM-DD') + 'T23:59:59Z',
        },
        callback: (response, error) => {
          hideSpinner();
          if (response && !error) {
            setUtcSlots(response);
            setLocalSlots(convertSlotsToLocalArray(response));
          }
        },
      });
    }
  };

  const getTypeOfApt = (psych_charges_with_packages) => {
    let finalArray = [];
    if (!!psych_charges_with_packages) {
      const entryKeys = Object.keys(psych_charges_with_packages);
      entryKeys.forEach((item) =>
        finalArray.push(psych_charges_with_packages[item])
      );
    }

    return finalArray;
  };

  const onSelectedProviderChange = (provider) => {
    // console.log('onSelectedProviderChange: ', provider);
    let tempAptType = null;
    let tempAptChoice = null;
    let tempDuration = null;
    let tempAmount = null;
    let tempImage = null;
    let tempName = null;
    let tempOverview = null;
    let tempPackageDesc = '';

    if (provider && provider['psych_details']) {
      tempImage = provider['psych_details']['psychiatrist_details'][
        'profile_image_url'
      ]
        ? provider['psych_details']['psychiatrist_details']['profile_image_url']
        : 'https://s3.us-west-2.amazonaws.com/psychonline.upload/defaultpic.png';
      tempName = provider['psych_details']['name']?.toString();
      tempOverview =
        provider['psych_details']['psychiatrist_details'][
          'overview'
        ]?.toString();
    }

    provider['choice_of_appointment'].map((item) => {
      if (item['checked'] && !tempAptChoice) {
        tempAptChoice = item['value'].toString();
      }
    });

    const responseBack = getTypeOfApt(provider['psych_charges_with_packages']);
    responseBack.forEach((items, indexes) => {
      items['items'].forEach((item, index) => {
        if (!!item['active'] && !tempAptType) {
          tempAmount = item['psych_cost_amount'].toString();
          tempDuration = item['psych_cost_duration'].toString();
          tempAptType = item['id'].toString();
          tempPackageDesc = !!item['package_description']
            ? item['package_description']
            : '';
        }
      });
    });
    setImage(tempImage);
    setName(tempName);
    setOverview(tempOverview);
    setAmount(tempAmount);
    setAptType(tempAptType);
    setDuration(tempDuration);
    setAptChoice(tempAptChoice);
    setPackageDesc(tempPackageDesc);
    setSelectedPsychDetails(provider);
    callProviderSlotAPI(
      activeMonthYearData,
      aptType,
      provider.psych_details['team_id'],
      provider.psych_details['id']
    );
  };
  const onProviderChange = (id) => {
    // console.log('onProviderChange id: ', id);
    providerDetails.forEach((provider) => {
      if (provider.psych_details['id'] == id) {
        onSelectedProviderChange(provider);
      }
    });
  };
  const onCalendarDayClicked = (date) => {
    setIsInitalDateSelected(true);
    let tempFilteredEvents = [];
    setSelectedDate({
      isSelected: true,
      formatted: moment(date).format('dddd, MMMM DD'),
      unFormatted: date,
    });
    localSlots.forEach((el) => {
      if (
        moment(date).format('YYYY-MM-DD') ===
        moment(el.start).format('YYYY-MM-DD')
      ) {
        if (el.start > new Date() && el.start < el.end) {
          tempFilteredEvents.push({
            start: moment(el.start).format('hh:mma'),
            end: moment(el.end).format('hh:mma'),
          });
        }
      }
    });
    setFilteredEvents(tempFilteredEvents);
    // console.log("tempFilteredEvents: ", tempFilteredEvents);
  };

  const onCalendarMonthChange = (data) => {
    // console.log('onCalendarMonthChange data: ', data);
    setActiveMonthData(data);
    callProviderSlotAPI(
      data,
      aptType,
      selectedPsychDetails.psych_details['team_id'],
      selectedPsychDetails.psych_details['id']
    );
  };

  const onAptChoiceChange = (value) => {
    // console.log('onAptChoiceChange: ', value);
    if (value == '1') {
      setIsCashPayment(false);
    }
    setAptChoice(value);
  };
  const onAptTypeChange = (value) => {
    // console.log('onAptTypeChange: ', value);
    const responseBack = getTypeOfApt(
      selectedPsychDetails['psych_charges_with_packages']
    );
    responseBack.forEach((items, indexes) => {
      items['items'].forEach((item, index) => {
        if (item['id'].toString() == value) {
          setAmount(item['psych_cost_amount']);
          setDuration(item['psych_cost_duration']);
          setPackageDesc(
            !!item['package_description'] ? item['package_description'] : ''
          );
        }
      });
    });
    setAptType(value);
    callProviderSlotAPI(
      activeMonthYearData,
      value,
      selectedPsychDetails.psych_details['team_id'],
      selectedPsychDetails.psych_details['id']
    );
  };

  const onSlotClick = (slot) => {
    setSelectedSlot(slot);
  };

  const onBookApt = (formData, success, failure) => {
    var selectedDateSlot = moment(
      moment(selectedDate['unFormatted']).format('YYYY-MM-DD') +
        ' ' +
        selectedSlot['start'],
      'YYYY-MM-DD hh:mm a'
    ).utc();
    var endSelectedDateSlot = moment(
      moment(selectedDate['unFormatted']).format('YYYY-MM-DD') +
        ' ' +
        selectedSlot['end'],
      'YYYY-MM-DD hh:mm a'
    ).utc();

    const payload = {
      type: 1,
      status: '4',
      charge: amount,
      patient_name: formData['name'],
      patient_email: formData['email'],
      patient_phone: formData['phone'],
      typeOfappointment: parseInt(aptType),
      starttime: selectedDateSlot.format('HH:mm'),
      endtime: endSelectedDateSlot.format('HH:mm'),
      date: selectedDateSlot.format('YYYY-MM-DD'),
      choiceOfappointment: aptChoice.toString(),
      cash_payment: isCashPayment ? 1 : 0,
      pysch_id: selectedPsychDetails.psych_details['id'],
    };
    // console.log('payload: ', payload);
    createNonLoggedInPatientAppointment({
      payload,
      callback: (respose, error) => {
        if (error) {
          showSnackbar({
            label: 'Something went wrong, please try again later.',
            type: 'error',
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          failure();
          return;
        }
        success();
        setIsAptBookSuccess(true);
        if (type == 'redirect') {
          showSnackbar({
            label: 'Booked successfully.',
            type: 'success',
          });
          setTimeout(() => {
            window.location.replace(formData['redirecturl']);
          }, 2000);
        }
        // console.log('error: ', error);
        // console.log('respose: ', respose);
      },
    });
  };

  const on_post_form_backPress = () => {
    setSelectedSlot(null);
  };

  const back = () => {
    setCount(false);
  };

  const submit = (formData, success, failure) => {
    setApiTeamId(team);
    const payload = {
      name: formData['name'],
      email: formData['email'],
      phone: formData['phone'],
    };
    submitPatientDetails({
      payload,
      callback: (respose, error) => {
        if (error) {
          showSnackbar({
            label: 'Something went wrong, please try again later.',
            type: 'error',
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          failure();
          return;
        }
        success();
        setIsSubmitted(true);
        showSnackbar({
          label:
            'Details have been submitted. We will get back to you shortly !',
          type: 'success',
        });
        setTimeout(() => {
          setCount(false);
        }, 2000);
      },
    });
  };

  const getPatientDetails = () => {
    setCount(true);
  };

  if (count) {
    return (
      <div className="container home-page-container">
        <Card className="home-page-card-container">
          <Header headerText={headerText} />
          <ArrowBackButton onClick={() => back()} />
          <DetailForm
            onParamsError={() => setIsError(true)}
            submit={(formData, success, failure) =>
              submit(formData, success, failure)
            }
          />
        </Card>
      </div>
    );
  }

  const onProviderReloadPress = () => {
    setIsError(false);
    setSelectedDate({
      isSelected: false,
      formatted: null,
      unFormatted: null,
    });
    initCallProviderDetailsAPI();
  };

  if (!providerDetails && providerDetails != 'error') {
    return (
      <div className="container pre-load-container d-flex justify-content-center align-items-center">
        <Loader label="Getting provider details. please wait..." />
      </div>
    );
  }

  if (providerDetails == 'error' || providerDetails.length < 1 || isError) {
    return (
      <div className="container pre-load-container d-flex justify-content-center align-items-center">
        <Error
          title="Failed to load provider details, Please verify URL or try again after sometime."
          showReloadButton
          onReloadPress={() => onProviderReloadPress()}
        />
      </div>
    );
  }

  if (!selectedPsychDetails) {
    return (
      <div className="container pre-load-container d-flex justify-content-center align-items-center">
        <Loader label="Getting things ready. please wait..." />
      </div>
    );
  }

  if (isAptBookSuccess) {
    return (
      <div className="container pre-load-container d-flex justify-content-center align-items-center">
        <div className="success-redirect-container">
          <Success title="Your appointment is scheduled succesfully, please check your email for further assistance." />
          {type == 'redirect' ? (
            <div style={{ color: Colors.info }}>
              Redirecting, please wait...
            </div>
          ) : (
            <Button
              onClick={() => window.location.reload()}
              variant="contained"
              endIcon={<Home />}
            >
              Home
            </Button>
          )}
        </div>
      </div>
    );
  }

  if (isSubmitted) {
    return (
      <div className="container pre-load-container d-flex justify-content-center align-items-center">
        <div className="success-redirect-container">
          <Success title="Thank You. We will get back to you !" />
          {type == 'redirect' ? (
            <div style={{ color: Colors.info }}>
              Redirecting, please wait...
            </div>
          ) : (
            <Button
              onClick={() => window.location.reload()}
              variant="contained"
              endIcon={<Home />}
            >
              Home
            </Button>
          )}
        </div>
      </div>
    );
  }
  if (selectedSlot) {
    return (
      <div className="container home-page-container">
        <Card className="home-page-card-container">
          <Header headerText={headerText} />
          <ArrowBackButton onClick={() => on_post_form_backPress()} />
          <PostFormUI
            type={type}
            onParamsError={() => setIsError(true)}
            onBookApt={(formData, success, failure) =>
              onBookApt(formData, success, failure)
            }
          />
        </Card>
      </div>
    );
  }

  return (
    <div className="container home-page-container">
      <Card className="home-page-card-container">
        <Header headerText={headerText} />
        <div className="row">
          <div className="col-md-12 col-lg-4 d-flex justify-content-center">
            <PreFormUI
              image={image}
              name={name}
              packageDesc={packageDesc}
              overview={overview}
              type={type}
              amount={amount}
              duration={duration}
              aptChoice={aptChoice}
              aptType={aptType}
              isCashPayment={isCashPayment}
              onProviderChange={(id) => onProviderChange(id)}
              onCashPaymentChange={(value) => setIsCashPayment(value)}
              onAptTypeChange={(value) => onAptTypeChange(value)}
              onAptChoiceChange={(value) => onAptChoiceChange(value)}
              selectedPsychDetails={selectedPsychDetails}
            />
          </div>
          <div
            className={`col-md-12 col-lg-3
            ${isInitalDateSelected ? 'col-lg-5' : 'col-lg-8'}
            d-flex  justify-content-center`}
          >
            <MonthCalendar
              disabled={
                !selectedPsychDetails?.psych_settings?.allow_public_appointment
                  ?.display
              }
              utcSlots={utcSlots}
              selectedDate={selectedDate.unFormatted}
              onCalendarDayClicked={(date) => onCalendarDayClicked(date)}
              onCalendarMonthChange={(data) => onCalendarMonthChange(data)}
            />
          </div>
          <div className="col-md-12 col-lg-3 d-flex justify-content-center">
            <div>
              <p>{selectedDate['formatted']}</p>
              <SlotItem
                items={filteredEvents}
                isInitalDateSelected={isInitalDateSelected}
                isDateSelected={selectedDate['isSelected']}
                onSlotClick={(slot) => onSlotClick(slot)}
              />
              <div>
                <div>
                  {isInitalDateSelected && filteredEvents.length == 0 ? (
                    <PrimaryButton
                      fullWidth
                      loading={loading}
                      marginbottom="15px"
                      label="No Slots? Contact us !"
                      onClick={() => getPatientDetails()}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

const Header = ({ headerText }) => {
  return (
    <div className="row">
      <div className="col header-container">
        <div className="header-text">Welcome To {headerText}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  providerDetails: state.User.providerDetails,
  providerAvailSlots: state.Slot.providerAvailSlots,
});

const mapDispatchToProps = (dispatch) => ({
  showSpinner: dispatch.Component.showSpinner,
  hideSpinner: dispatch.Component.hideSpinner,
  showSnackbar: dispatch.Component.showSnackbar,
  getProviderAvailSlots: dispatch.Slot.getProviderAvailSlots,
  getProviderDetails: dispatch.User.getProviderDetails,
  createNonLoggedInPatientAppointment:
    dispatch.Appointment.createNonLoggedInPatientAppointment,
  submitPatientDetails: dispatch.Appointment.submitPatientDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
