const Images = {
    psychOnlineLogo: require('../assets/images/psychOnlineLogo.png'),
    up: require('../assets/images/up.png'),
    dropdown: require('../assets/images/dropdown.png'),
    cancelDark: require('../assets/images/cancelDark.png'),
    checked1: require('../assets/images/checked1.png'),
    checked2: require('../assets/images/checked2.png'),
    user: require('../assets/images/user.png'),
    rightArrow: require('../assets/images/rightArrow.png'),
    leftArrow: require('../assets/images/leftArrow.png'),
    edit: require('../assets/images/edit.png'),
    creditCard: require('../assets/images/credit-card.png'),
    unionPay: require('../assets/images/union-pay.png'),
    dinnersClub: require('../assets/images/dinners-club.png'),
    americanExpress: require('../assets/images/american-express.png'),
    discover: require('../assets/images/discover.png'),
    amex: require('../assets/images/amex.png'),
    masterCard: require('../assets/images/master-card.png'),
    visa: require('../assets/images/visa.png'),
    premiumIcon: require('../assets/images/premium_icon.png'),
    selection: require('../assets/images/selection.png'),
    addIcon: require('../assets/images/addIcon.png'),
    micImage:
      'https://raw.githubusercontent.com/AboutReact/sampleresource/master/microphone.png',
    apiCallError: require('../assets/images/apiCallError.jpg'),
    uploadIcon: require('../assets/images/upload_icon.png'),
    mic: require('../assets/images/mic.jpeg'),
    chatIcon: require('../assets/images/chat-icon.png'),
    shareIcon: require('../assets/images/shareIcon.png'),
    delete: require('../assets/images/delete.png'),
    greenTick: require('../assets/images/green-tick.png'),
  };
  
  export default Images;
  