import axios from 'axios';
import store from '../store';
import { Envs } from '../config';
import { ApiCall } from '../services';

export default {
  state: {},
  /**
   * Reducers
   */
  reducers: {},

  /**
   * Effects/Actions
   */
  effects: {
    async createNonLoggedInPatientAppointment({ payload, callback }) {
      const apiCall = new ApiCall();
      try {
        apiCall
          .createNonLoggedInPatientAppointment(payload)
          .then((response) => {
            if (response.status === 200) {
              const data = response.data;
              callback(data, null);
            }
          })
          .catch((err) => {
            callback(null, err);
            console.log('Err createNonLoggedInPatientAppointment: ', err);
          });
      } catch (err) {
        console.log('Err createNonLoggedInPatientAppointment: ', err);
      }
    },

    async submitPatientDetails({ payload, callback }) {
      const apiCall = new ApiCall();
      try {
        apiCall
          .submitPatientDetails(payload)
          .then((response) => {
            if (response.status === 200) {
              const data = response.data;
              callback(data, null);
            }
          })
          .catch((err) => {
            callback(null, err);
            console.log('Err submitPatientDetails: ', err);
          });
      } catch (err) {
        console.log('Err submitPatientDetails: ', err);
      }
    },
  },
};
