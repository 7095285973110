import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';
import { getDateBetweensTwoDate } from '../../../services';
import { Images } from '../../../config';
import moment from 'moment';
import './MonthCalendar.css';

export default class MonthCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datesArray: [],
      dateObject: moment(),
      displayDatesArray: [],
      showPrevMonthButton: false,
      weekdayshort: moment.weekdaysShort(),
    };
  }
  componentDidMount() {
    var dt = new Date();
    dt.setHours(0, 0, 0, 0);
    this.initCalendar();
  }

  initCalendar(reInitData) {
    this.props.onCalendarMonthChange({
      isCurrentMonth: this.state.dateObject.isSameOrBefore(moment()),
      monthYear: this.state.dateObject.format('MMMM-YYYY'),
    });
    if (reInitData) {
      var startDate = moment(this.state.dateObject).startOf('month');
      var endDate = moment(startDate).endOf('month');
      let datesArray = getDateBetweensTwoDate(
        moment(startDate).set({
          hour: moment().get('hour'),
          minute: moment().get('minute'),
          second: moment().get('second'),
        }),
        moment(endDate)
      );
      this.buildCalendarDates(datesArray);
    } else {
      var startDate = moment(new Date().toString()).startOf('month');
      var endDate = moment(startDate).endOf('month');
      let datesArray = getDateBetweensTwoDate(
        moment(startDate).set({
          hour: moment().get('hour'),
          minute: moment().get('minute'),
          second: moment().get('second'),
        }),
        moment(endDate)
      );
      this.buildCalendarDates(datesArray);
    }
  }

  daysInMonth = () => {
    return this.state.dateObject.daysInMonth();
  };

  currentDay = () => {
    return this.state.dateObject.format('D');
  };

  firstDayOfMonth = () => {
    let dateObject = this.state.dateObject;
    let firstDay = moment(dateObject).startOf('month').format('d'); // Day of week 0...1..5...6
    return firstDay;
  };

  buildCalendarDates(dates) {
    var temp = [];
    let blanks = [];
    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      blanks.push({ date: '' });
    }
    var tempDates = [...blanks, ...dates];
    for (let index = 0; index < 6; index++) {
      temp.push({
        week: tempDates.splice(0, 7),
      });
    }
    this.setState({
      datesArray: temp,
      showPrevMonthButton: !this.state.dateObject.isSameOrBefore(moment()),
    });
  }

  onPrev = () => {
    this.props.onCalendarDayClicked(null);
    this.setState(
      {
        dateObject: this.state.dateObject.subtract(1, 'month'),
      },
      () => {
        this.initCalendar(this.state.dateObject);
        this.props.onCalendarMonthChange({
          isCurrentMonth: this.state.dateObject.isSameOrBefore(moment()),
          monthYear: this.state.dateObject.format('MMMM-YYYY'),
        });
      }
    );
  };
  onNext = () => {
    this.props.onCalendarDayClicked(null);
    this.setState(
      {
        dateObject: this.state.dateObject.add(1, 'month'),
      },
      () => {
        this.initCalendar(this.state.dateObject);
        this.props.onCalendarMonthChange({
          isCurrentMonth: this.state.dateObject.isSameOrBefore(moment()),
          monthYear: this.state.dateObject.format('MMMM-YYYY'),
        });
      }
    );
  };
  onDateClick = (date) => {
    if (date && !this.props.disabled) {
      this.props.onCalendarDayClicked(date);
    }
  };
  render() {
    return (
      <div className="month-calendar-parent-container">
        <div className="top-nav-container">
          {this.state.showPrevMonthButton ? (
            <div className="button-prev-container">
              <img
                className="button-prev"
                onClick={(e) => this.onPrev()}
                src={Images.leftArrow}
                alt="right-arrow"
              />
            </div>
          ) : (
            <div></div>
          )}
          <p className="year-container">
            {this.state.dateObject.format('MMMM') +
              ' ' +
              this.state.dateObject.format('Y')}
          </p>
          <div className="button-next-container">
            <img
              className="button-next"
              onClick={(e) => this.onNext()}
              src={Images.rightArrow}
              alt="right-arrow"
            />
          </div>
        </div>
        <div className="calendar-container ">
          <table cellSpacing="10" cellPadding="10">
            <caption></caption>
            <thead>
              <tr>
                <th className="weekHeader">Sun</th>
                <th className="weekHeader">Mon</th>
                <th className="weekHeader">Tue</th>
                <th className="weekHeader">Wed</th>
                <th className="weekHeader">Thu</th>
                <th className="weekHeader">Fri</th>
                <th className="weekHeader">Sat</th>
              </tr>
            </thead>
            <tbody>
              {this.state.datesArray.map((date, index) => (
                <tr key={index}>
                  {date['week'].map((it, ind) => {
                    if (it['date']) {
                      return (
                        <td className="date-td" key={ind}>
                          <div
                            className={`date ${
                              it['date'] == this.props.selectedDate
                                ? 'selectedDate'
                                : ''
                            } ${!it['isPast'] ? 'disabled' : 'active'}`}
                            onClick={
                              it['isPast']
                                ? () => this.onDateClick(it['date'])
                                : () => {}
                            }
                            style={{
                              height: isBrowser ? '35px' : '',
                              width: isBrowser ? '35px' : '',
                            }}
                          >
                            <div className="date-and-indicator-container">
                              <div>{it['formattedDate']}</div>
                              {this.props.utcSlots[it['ymd_date']] &&
                              this.props.utcSlots[it['ymd_date']].length > 0 &&
                              !this.props.disabled ? (
                                <div className="month-calendar-indicator"></div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          </div>
                        </td>
                      );
                    } else {
                      return <td key={ind}></td>;
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
