const Colors = {
    primary: '#3C97E8',
    secondary: '#5F951A',
    light1: '#F6F8FA',
    light2: '#7c7c7c',
    border: '#B5C1C5',
    black: '#000000',
    white: '#FFFFFF',
    darkGrey: '#738991',
    nonTransparent: 'rgba(0,0,0,1)',
    lessTransparent: 'rgba(0,0,0,0.8)',
    moderateTransparent: 'rgba(0,0,0,0.7)',
    transparent: 'rgba(0,0,0,0.2)',
    fullTransparent: 'rgba(0,0,0,0)',
    danger: '#dc3545',
    warning: '#ffc107',
    success: '#28a745',
    info: '#17a2b8',
    blue: '#0E3FA9',
    background: '#f1f1f1',
    green: '#56BE79',
    red: '#FF0000',
    yellow: '#FB9702',
  };
  
  export default Colors;
  