import moment from "moment";

export function isValidEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}
export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export function openRazorpay(options, callback) {
  options.handler = (response, error) => {
    callback(response, error);
  };
  options.modal = {
    ondismiss: () => callback(null, true),
  };
  loadScript("https://checkout.razorpay.com/v1/checkout.js")
    .then(() => {
      var rzp = new window.Razorpay(options);
      rzp.open();
    })
    .catch((error) => console.log("openRazorpay loadScript err: ", error));
}

export function getDateBetweensTwoDate(currentDate, endMonthDate) {
  var dates = [];
  var now = currentDate.clone();
  var newCurrentDate = new Date();
  newCurrentDate.setHours(0, 0, 0, 0);
  while (now.isSameOrBefore(endMonthDate)) {
    dates.push({
      isPast: moment(new Date(now)).isSameOrAfter(newCurrentDate.toString()),
      formattedDate: now.format("DD"),
      ymd_date: moment(new Date(now)).format("YYYY-MM-DD"),
      day: now.format("ddd"),
      date: new Date(now),
      available_slots: 0,
    });
    now.add(1, "days");
  }
  return dates;
}

export function convertSlotsToLocalArray(slots) {
  let events = [];
  Object.keys(slots).forEach(function (el, key) {
    slots[el].forEach((element) => {
      var slot_explode = element.split(" - ");
      const start = moment.utc(slot_explode[0], "YYYY-MM-DD HH:mm:ss").local();
      const end = moment.utc(slot_explode[1], "YYYY-MM-DD HH:mm:ss").local();
      events.push({
        start: start,
        end: end,
      });
    });
  });
  return events;
}
