import axios from 'axios';
import store from '../store';
import { ApiCall } from '../services';
import { Envs } from '../config';

export default {
  state: {
    providerAvailSlots: null,
  },
  /**
   * Reducers
   */
  reducers: {
    getProviderAvailSlotsReducer(state, data) {
      return {
        ...state,
        providerAvailSlots: data['psych_availability'],
      };
    },
  },

  /**
   * Effects/Actions
   */
  effects: {
    async getProviderAvailSlots({ payload, callback }) {
      const apiCall = new ApiCall();
      try {
        apiCall
          .getProviderAvailSlots(payload)
          .then((response) => {
            if (response.status === 200) {
              const data = response.data;
              this.getProviderAvailSlotsReducer(data);
              callback(data['psych_availability'], null);
            }
          })
          .catch((err) => {
            callback(null, err);
            this.getProviderAvailSlotsReducer('error');
            console.log('Err getProviderAvailSlots: ', err);
          });
      } catch (err) {
        console.log('Err getProviderAvailSlots: ', err);
      }
    },
  },
};
