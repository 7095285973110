import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormLabel } from '@mui/material';
import './PackageDropdown.css';

const PackageDropdown = ({
  items,
  label,
  value,
  disabled,
  marginTop,
  marginBottom,
  onChange = () => {},
}) => {
  // console.log('items: ', items);
  //   return null;
  return (
    <FormControl fullWidth style={{ marginTop, marginBottom, width: '320px' }}>
      <FormLabel className="single-select-label-text">
        {label && label}
      </FormLabel>
      <Select
        native
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value ? value : ''}
        placeholder="Please select"
        onChange={(event) => onChange(event.target.value)}
      >
        {items.map((item_s, indexes) => {
          return (
            <optgroup key={indexes} label={item_s['name']}>
              {item_s['items'].map((item, index) => {
                if (!!item['active']) {
                  return (
                    <option key={index} value={item['id']}>
                      {item['appointment_name']}
                    </option>
                  );
                }
              })}
            </optgroup>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default PackageDropdown;
